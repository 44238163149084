/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, CardPopup, Text } from '@sealfye/ui-components';
import classnames from 'classnames';
import { IoArrowDownCircleOutline } from 'react-icons/io5';
// eslint-disable-next-line import/no-unresolved
import { useRegisterSW } from 'virtual:pwa-register/react';

import { usePwa } from '../../../context/PwaContext';
import { BaseComponentProps } from '../../../types/base-component.types';

import styles from './PwaReloadPopup.module.scss';

function PwaReloadPopup({
  className,
  testId = 'ui-pwa-reload-popup',
}: BaseComponentProps) {
  const { needUpdate, setNeedUpdate } = usePwa();

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(
      swScriptUrl: string,
      registration: ServiceWorkerRegistration | undefined,
    ) {
      console.log('Registered SW for scope:', registration?.scope);
    },
    onRegisterError(error: any) {
      console.error('SW registration error', error);
    },
  });

  const close = () => {
    setNeedRefresh(false);
  };

  if (needUpdate && !needRefresh) {
    return (
      <CardPopup
        className={classnames(styles['main'], className)}
        onClose={() => {
          close();
        }}
        closeable={false}
        animation="bounceInUp"
        testId={testId}
      >
        <div className={styles['modal']}>
          <div className={styles['icon']}>{<IoArrowDownCircleOutline />}</div>
          <div className={styles['text']}>
            <span className={styles['slogan']}>
              Hay una nueva versión disponible, recarga la app para actualizar.
            </span>
            <Text variety="annotation">
              Actualiza la app para disfrutar de los últimos cambios, nueva
              funcionalidad, mejoras de rendimiento y correccion de errores.
            </Text>
          </div>
          <Button
            className={styles['button']}
            variant="outline-premium"
            onClick={() => window.location.reload()}
          >
            Recargar
          </Button>
        </div>
      </CardPopup>
    );
  }

  if (needRefresh) {
    return (
      <CardPopup
        className={classnames(styles['main'], className)}
        onClose={() => {
          close();
        }}
        closeable={false}
        animation="bounceInUp"
        testId={testId}
      >
        <div className={styles['modal']}>
          <div className={styles['icon']}>{<IoArrowDownCircleOutline />}</div>
          <div className={styles['text']}>
            <span className={styles['slogan']}>¡Nueva versión disponible!</span>
            <Text variety="annotation">
              Actualiza la app para disfrutar de los últimos cambios, nueva
              funcionalidad, mejoras de rendimiento y correccion de errores.
            </Text>
          </div>
          <Button
            className={styles['button']}
            variant="outline-premium"
            onClick={() => updateServiceWorker(true)}
          >
            Actualizar
          </Button>
        </div>
      </CardPopup>
    );
  }

  return null;
}

export { PwaReloadPopup };
