/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { ApiResponse } from '../response';

export interface TheoryLessonDto {
  id: number;
  title: string;
  block: string;
  description: string;
}

export function useTheoryLesson() {
  const { instance } = useAxios();

  async function getTheoryLessons(): Promise<ApiResponse<TheoryLessonDto[]>> {
    try {
      const response = await instance.get<TheoryLessonDto[]>(`/theory/lessons`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  return { getTheoryLessons };
}
