/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { useToasterActions } from '../../../state/toasterStore';
import { ApiResponse } from '../response';
import { PagedResponse } from './usePageRequest';

export interface FeedbackReportDto {
  id: string;
  username: string;
  email: string;
  description: string;
  date: Date;
}

export interface QuestionFeedbackReportDto extends FeedbackReportDto {
  questionId: string;
}

export interface SchemaFeedbackReportDto extends FeedbackReportDto {
  schemaId: string;
}

export function useFeedback() {
  const { instance } = useAxios();
  const { sendMessage } = useToasterActions();

  async function reportError(description: string): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(`/feedback/errors`, {
        description,
      });

      sendMessage('¡Error reportado!', 'Gracias compañerx 💙', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function reportByeFeedback(
    description: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(`/feedback/bye`, { description });

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getReportedErrors(
    pageNumber = 1,
    pageSize = 10,
  ): Promise<ApiResponse<PagedResponse<FeedbackReportDto>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    };

    try {
      const response = await instance.get<PagedResponse<FeedbackReportDto>>(
        `/feedback/errors`,
        config,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function deleteFeedback(feedbackId: string): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(`/feedback/${feedbackId}`);

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  return {
    reportError,
    reportByeFeedback,
    getReportedErrors,
    deleteFeedback,
  };
}
