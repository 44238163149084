/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { useToasterActions } from '../../../state/toasterStore';
import { QuestionDto } from '../questions';
import { ApiResponse } from '../response';
import { QuestionFeedbackReportDto } from './useFeedback';
import { PagedResponse } from './usePageRequest';

export interface EnglishQuestionDto extends QuestionDto {
  category: number;
  categoryName: string;
  question: string;
  answers: string[];
  reason: string;
  bookmarked?: boolean;
  status: number;
}

export interface DetailedEnglishQuestionDto extends EnglishQuestionDto {
  author: string;
  creationDate: Date;
  trial: boolean;
}

export interface EnglishQuestionPostDto {
  category: number;
  question: string;
  aAnswer: string;
  bAnswer: string;
  cAnswer: string;
  dAnswer: string;
  correctAnswer: number;
  reason: string;
  trial: boolean;
}
export function useEnglishQuestion() {
  const { instance } = useAxios();
  const { sendMessage } = useToasterActions();

  async function getEnglishQuestion(
    id: string,
  ): Promise<ApiResponse<EnglishQuestionDto>> {
    try {
      const response = await instance.get<EnglishQuestionDto>(
        `/english/questions/${id}`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      if (error.response.status === 404) {
        sendMessage('¡Ups!', 'Parece que la pregunta no existe', 'danger');

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function getDetailedEnglishQuestion(
    id: string,
  ): Promise<ApiResponse<DetailedEnglishQuestionDto>> {
    try {
      const response = await instance.get<DetailedEnglishQuestionDto>(
        `/english/questions/${id}/detail`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getEnglishQuestions(
    status: number[],
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<DetailedEnglishQuestionDto>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
        status,
      },
    };

    try {
      const response = await instance.get<
        PagedResponse<DetailedEnglishQuestionDto>
      >(`/english/questions`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getRandomEnglishQuestions(
    categories?: number[],
  ): Promise<ApiResponse<EnglishQuestionDto>> {
    const config = {
      params: {
        categories,
      },
    };

    try {
      const response = await instance.get<EnglishQuestionDto>(
        `/english/questions/random`,
        config,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function addEnglishQuestion(
    question: EnglishQuestionPostDto,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(`/english/questions`, question);

      sendMessage('¡Hecho!', 'Pregunta creada correctamente', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage(
          'Fallo de validación',
          'Parece que la pregunta ya existe o hay algún campo incorrecto',
          'danger',
        );

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function updateEnglishQuestion(
    questionId: string,
    question: EnglishQuestionPostDto,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(
        `/english/questions/${questionId}`,
        question,
      );

      sendMessage('¡Hecho!', 'Pregunta actualizada correctamente', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage(
          'Fallo de validación',
          'Parece que la pregunta ya existe o hay algún campo incorrecto',
          'danger',
        );

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function deleteEnglishQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(
        `/english/questions/${questionId}`,
      );

      sendMessage(
        '¡Hecho!',
        'Pregunta marcada como obsoleta correctamente',
        'success',
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage(
          '¡Ups!',
          'Parece que la pregunta no existe o no tienes permisos para eliminarla',
          'danger',
        );

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function bookmarkEnglishQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.put(
        `/english/questions/${questionId}/bookmark`,
        {},
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function unbookmarkEnglishQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(
        `/english/questions/${questionId}/bookmark`,
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function reportEnglishQuestionError(
    questionId: string,
    description: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(
        `/english/questions/${questionId}/errors`,
        {
          description,
        },
      );

      sendMessage('¡Error reportado!', 'Gracias compañerx 💙', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getEnglishQuestionReportedErrors(
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<QuestionFeedbackReportDto>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    };

    try {
      const response = await instance.get<
        PagedResponse<QuestionFeedbackReportDto>
      >(`/english/questions/errors`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function deleteEnglishQuestionReportedError(
    questionId: string,
    feedbackId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(
        `/english/questions/${questionId}/errors/${feedbackId}`,
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function verifyEnglishQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.put(
        `/english/questions/${questionId}/verify`,
        {},
      );

      sendMessage('¡Hecho!', 'Pregunta verificada correctamente', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage('¡Ups!', 'Parece que la pregunta no existe', 'danger');

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function rejectEnglishQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(
        `/english/questions/${questionId}/verify`,
      );

      sendMessage('¡Hecho!', 'Pregunta rechazada correctamente', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage('¡Ups!', 'Parece que la pregunta no existe', 'danger');

        return {
          success: error.response.status === 400,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  return {
    getDetailedEnglishQuestion,
    getEnglishQuestions,
    getEnglishQuestion,
    getRandomEnglishQuestions,
    addEnglishQuestion,
    updateEnglishQuestion,
    deleteEnglishQuestion,
    bookmarkEnglishQuestion,
    unbookmarkEnglishQuestion,
    reportEnglishQuestionError,
    getEnglishQuestionReportedErrors,
    deleteEnglishQuestionReportedError,
    verifyEnglishQuestion,
    rejectEnglishQuestion,
  };
}
