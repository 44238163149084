/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { TestDto } from '../questions';
import { ApiResponse } from '../response';
import { TheoryQuestionDto } from './useTheoryQuestion';
import { UserScoreDto } from './useTheoryTest';

export interface TheoryOfficialExamDto {
  id: string;
  name: string;
  year: number;
  examination: string;
}

export interface TheoryOfficialExamMarkRankingDto {
  exam: TheoryOfficialExamDto;
  mark: number;
  averageScore: number;
  position: number;
  total: number;
  userScores: UserScoreDto[];
}

export interface TheoryOfficialExamMarkPostDto {
  totalAnswers: number;
  correctAnswers: number;
  wrongAnswers: number;
}

export function useTheoryOfficialExam() {
  const { instance } = useAxios();

  async function getTheoryOfficialExams(): Promise<
    ApiResponse<TheoryOfficialExamDto[]>
  > {
    try {
      const response =
        await instance.get<TheoryOfficialExamDto[]>(`/theory/exams`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getLastOfficialExam(): Promise<
    ApiResponse<TheoryOfficialExamDto>
  > {
    try {
      const response =
        await instance.get<TheoryOfficialExamDto>(`/theory/exams/last`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getOfficialExam(
    id: string,
  ): Promise<ApiResponse<TestDto<TheoryQuestionDto>>> {
    try {
      const response = await instance.get<TestDto<TheoryQuestionDto>>(
        `/theory/exams/${id}`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getOfficialExamRanking(
    id: string,
  ): Promise<ApiResponse<TheoryOfficialExamMarkRankingDto>> {
    try {
      const response = await instance.get<TheoryOfficialExamMarkRankingDto>(
        `/theory/exams/${id}/ranking`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function addOfficialExamMark(
    id: string,
    mark: TheoryOfficialExamMarkPostDto,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(`/theory/exams/${id}/mark`, {
        ...mark,
      });

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  return {
    getTheoryOfficialExams,
    getLastOfficialExam,
    getOfficialExam,
    getOfficialExamRanking,
    addOfficialExamMark,
  };
}
