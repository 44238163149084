import { useRef, useState } from 'react';

import {
  Button,
  ButtonGroup,
  CardPopup,
  FormFeedback,
  FormGroup,
  FormLabel,
  QuestionAnswer,
  SelectDropdown,
  SwitchCheck,
  Textarea,
  Tooltip,
} from '@sealfye/ui-components';
import { useIsMutating, useMutation, useQueries } from '@tanstack/react-query';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import { IoTrashOutline } from 'react-icons/io5';
import * as Yup from 'yup';

import { useEnglishCategory } from '../../../../services/api/hooks/useEnglishCategory';
import {
  EnglishQuestionPostDto,
  useEnglishQuestion,
} from '../../../../services/api/hooks/useEnglishQuestion';
import { useEditor } from '../../../../state/editorStore';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { Loading } from '../../../core/Loading';
import { Editor } from '../../../shared/editor/Editor';

import styles from './EnglishQuestionEditor.module.scss';

type ContainerProps = BaseComponentProps & {
  id?: string;
  onClose: () => void;
};

function EnglishQuestionEditor({
  className,
  id = '00000000-0000-0000-0000-000000000000',
  onClose,
  testId = 'ui-question-editor',
}: ContainerProps) {
  const nodeRef = useRef<HTMLDivElement>(null);
  const nodeRefPreview = useRef<HTMLDivElement>(null);

  const editor = useEditor();

  const [showPreview, setShowPreview] = useState(false);

  const [payload, setPayload] = useState<EnglishQuestionPostDto>();

  const { getEnglishCategories } = useEnglishCategory();
  const {
    getDetailedEnglishQuestion,
    updateEnglishQuestion,
    addEnglishQuestion,
    deleteEnglishQuestion,
  } = useEnglishQuestion();

  const [categoriesQuery, payloadQuery] = useQueries({
    queries: [
      {
        queryKey: ['getEnglishCategories'],
        queryFn: () => getEnglishCategories(),
      },
      {
        queryKey: ['getDetailedEnglishQuestion', id],
        queryFn: () => getDetailedEnglishQuestion(id),
      },
    ],
  });

  const updateMutation = useMutation({
    mutationKey: ['updateEnglishQuestion', id],
    mutationFn: (payload: EnglishQuestionPostDto) =>
      updateEnglishQuestion(id, payload),
    onSuccess: () => {
      editor?.onEdit && editor.onEdit();
      nodeRef.current?.click();
    },
  });

  const addMutation = useMutation({
    mutationKey: ['addEnglishQuestion'],
    mutationFn: (payload: EnglishQuestionPostDto) =>
      addEnglishQuestion(payload),
    onSuccess: () => {
      editor?.onEdit && editor.onEdit();
      nodeRef.current?.click();
    },
  });

  const isMutating = Boolean(
    useIsMutating({ mutationKey: ['addEnglishQuestion'] }),
  );

  const deleteMutation = useMutation({
    mutationKey: ['deleteEnglishQuestion'],
    mutationFn: (id: string) => deleteEnglishQuestion(id),
    onSuccess: () => {
      editor?.onDelete && editor.onDelete();
      nodeRef.current?.click();
    },
  });

  return (
    <>
      <CardPopup
        className={classnames(styles['main'], className)}
        refClose={nodeRef}
        onClose={onClose}
        closeOnOutsideClick={false}
        testId={testId}
      >
        <div className={styles['wrapper']}>
          {categoriesQuery.isLoading || payloadQuery.isLoading ? (
            <Loading />
          ) : (
            <Formik
              enableReinitialize
              validationSchema={Yup.object().shape({
                category: Yup.string().required(
                  'Especifica el tema de la pregunta.',
                ),
                question: Yup.string()
                  .min(10, 'La pregunta debe tener al menos 10 caracteres.')
                  .max(2000, 'La pregunta no debe superar los 2000 caracteres.')
                  .required('La pregunta no puede estar vacía.'),
                aAnswer: Yup.string()
                  .max(500, 'Las opciones no deben superar los 500 caracteres.')
                  .required('Las opciones no pueden estar vacías.'),
                bAnswer: Yup.string()
                  .max(500, 'Las opciones no deben superar los 500 caracteres.')
                  .required('Las opciones no pueden estar vacías.'),
                cAnswer: Yup.string()
                  .max(500, 'Las opciones no deben superar los 500 caracteres.')
                  .required('Las opciones no pueden estar vacías.'),
                dAnswer: Yup.string()
                  .max(500, 'Las opciones no deben superar los 500 caracteres.')
                  .required('Las opciones no pueden estar vacías.'),
                correctAnswer: Yup.string().required(
                  'Especifica la respuesta correcta.',
                ),
                reason: Yup.string().max(
                  2500,
                  'La explicación no debe superar los 2500 caracteres.',
                ),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);

                try {
                  if (values.preview) {
                    setPayload({
                      ...payload,
                      category: +values.category,
                      question: values.question,
                      aAnswer: values.aAnswer,
                      bAnswer: values.bAnswer,
                      cAnswer: values.cAnswer,
                      dAnswer: values.dAnswer,
                      correctAnswer: values.correctAnswer ?? 0,
                      reason: values.reason,
                      trial: values.trial,
                    });

                    setShowPreview(true);
                  } else {
                    const payloadToSubmit = {
                      category: +values.category,
                      question: values.question,
                      aAnswer: values.aAnswer,
                      bAnswer: values.bAnswer,
                      cAnswer: values.cAnswer,
                      dAnswer: values.dAnswer,
                      correctAnswer: values.correctAnswer ?? 0,
                      reason: values.reason,
                      trial: values.trial,
                    };

                    if (id === '00000000-0000-0000-0000-000000000000') {
                      addMutation.mutate(payloadToSubmit);
                    } else {
                      updateMutation.mutate(payloadToSubmit);
                    }
                  }
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                  throw new Error(error);
                }

                setSubmitting(false);
              }}
              initialValues={{
                category: payloadQuery.data?.data?.category || 1,
                question: payloadQuery.data?.data?.question || '',
                aAnswer: payloadQuery.data?.data?.answers[0] || '',
                bAnswer: payloadQuery.data?.data?.answers[1] || '',
                cAnswer: payloadQuery.data?.data?.answers[2] || '',
                dAnswer: payloadQuery.data?.data?.answers[3] || '',
                correctAnswer:
                  payloadQuery.data?.data?.correctAnswer || undefined,
                reason: payloadQuery.data?.data?.reason || '',
                trial: payloadQuery.data?.data?.trial || false,
                preview: false,
              }}
            >
              {({
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleSubmit,
              }) => (
                <Form
                  className={styles['form']}
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div className={styles['icon']}>{<span>✏️</span>}</div>
                  <FormGroup>
                    <FormLabel>Categoría</FormLabel>
                    <SelectDropdown
                      onSelect={(value) => {
                        handleChange({
                          target: {
                            name: 'category',
                            value,
                          },
                        });
                      }}
                      error={touched.category && !!errors.category}
                      options={
                        categoriesQuery.data?.data?.map((category) => ({
                          value: category.id,
                          label: category.id + ' - ' + category.title,
                          selected: values.category === category.id,
                        })) || []
                      }
                    />
                    <FormFeedback>
                      Podemos distinguir distintos tipos de bloques temáticos,
                      elige categoría para esta pregunta.
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Pregunta</FormLabel>
                    <Textarea
                      id="question"
                      value={values.question}
                      rows={5}
                      onChange={handleChange}
                      error={touched.question && !!errors.question}
                    />
                    {touched.question && errors.question && (
                      <FormFeedback variant="danger">
                        {errors.question}
                      </FormFeedback>
                    )}
                    <FormFeedback>
                      Todas las preguntas procederán de los contenidos
                      especificados en el temario oficial de Policía Nacional
                      Escala Básica.
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Opción A</FormLabel>
                    <Textarea
                      id="aAnswer"
                      value={values.aAnswer}
                      rows={4}
                      onChange={handleChange}
                      error={touched.aAnswer && !!errors.aAnswer}
                    />
                    {touched.aAnswer && errors.aAnswer && (
                      <FormFeedback variant="danger">
                        {errors.aAnswer}
                      </FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Opción B</FormLabel>
                    <Textarea
                      id="bAnswer"
                      value={values.bAnswer}
                      rows={4}
                      onChange={handleChange}
                      error={touched.bAnswer && !!errors.bAnswer}
                    />
                    {touched.bAnswer && errors.bAnswer && (
                      <FormFeedback variant="danger">
                        {errors.bAnswer}
                      </FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Opción C</FormLabel>
                    <Textarea
                      id="cAnswer"
                      value={values.cAnswer}
                      rows={4}
                      onChange={handleChange}
                      error={touched.cAnswer && !!errors.cAnswer}
                    />
                    {touched.cAnswer && errors.cAnswer && (
                      <FormFeedback variant="danger">
                        {errors.cAnswer}
                      </FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Opción D</FormLabel>
                    <Textarea
                      id="dAnswer"
                      value={values.dAnswer}
                      rows={4}
                      onChange={handleChange}
                      error={touched.dAnswer && !!errors.dAnswer}
                    />
                    {touched.dAnswer && errors.dAnswer && (
                      <FormFeedback variant="danger">
                        {errors.dAnswer}
                      </FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Opción correcta</FormLabel>
                    <ButtonGroup
                      onSelect={(value) => {
                        handleChange({
                          target: {
                            name: 'correctAnswer',
                            value,
                          },
                        });
                      }}
                      error={touched.correctAnswer && !!errors.correctAnswer}
                      options={[
                        {
                          value: 1,
                          label: 'A',
                          selected: values.correctAnswer === 1,
                        },
                        {
                          value: 2,
                          label: 'B',
                          selected: values.correctAnswer === 2,
                        },
                        {
                          value: 3,
                          label: 'C',
                          selected: values.correctAnswer === 3,
                        },
                        {
                          value: 4,
                          label: 'D',
                          selected: values.correctAnswer === 4,
                        },
                      ]}
                    />
                    {touched.correctAnswer && errors.correctAnswer && (
                      <FormFeedback variant="danger">
                        {errors.correctAnswer}
                      </FormFeedback>
                    )}
                    <FormFeedback>
                      Cada pregunta consta de 4 respuestas alternativas, siendo
                      solo 1 la correcta.
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Retroalimentación</FormLabel>
                    <Editor
                      content={values.reason}
                      onContentChange={(value) => {
                        handleChange({
                          target: {
                            name: 'reason',
                            value,
                          },
                        });
                      }}
                      error={touched.reason && !!errors.reason}
                    />
                    {touched.reason && errors.reason && (
                      <FormFeedback variant="danger">
                        {errors.reason}
                      </FormFeedback>
                    )}
                    <FormFeedback>
                      Cada explicación ayuda a entender y memorizar la
                      respuesta, siempre que puedas elabora una buena
                      descripción de los contenidos.
                    </FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Trial</FormLabel>
                    <SwitchCheck
                      label="Pregunta para usuarios con licencia gratuita"
                      checked={values.trial}
                      onChange={() => {
                        handleChange({
                          target: {
                            name: 'trial',
                            value: !values.trial,
                          },
                        });
                      }}
                    />
                    <FormFeedback>
                      Marca esta opción si quieres que esta pregunta sea visible
                      por usuarios con licencia gratuita.
                    </FormFeedback>
                  </FormGroup>

                  <div className={styles['footer']}>
                    {id !== '00000000-0000-0000-0000-000000000000' && (
                      <Tooltip
                        icon={
                          <IoTrashOutline className={styles['remove-icon']} />
                        }
                        placement="top"
                        behavior="click"
                      >
                        <div className={styles['tooltip-payload']}>
                          <span>¿Estás segurx?</span>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => {
                              deleteMutation.mutate(id);
                            }}
                          >
                            Eliminar
                          </Button>
                        </div>
                      </Tooltip>
                    )}
                    <Button
                      className={styles['button']}
                      loading={isSubmitting || isMutating}
                      disabled={isSubmitting || isMutating}
                      variant="outline-primary"
                      onClick={() => {
                        values.preview = true;
                        handleSubmit();
                      }}
                    >
                      Previsualizar
                    </Button>
                    <Button
                      className={styles['button']}
                      loading={isSubmitting || isMutating}
                      disabled={isSubmitting || isMutating}
                      variant="primary"
                      onClick={() => {
                        values.preview = false;
                        handleSubmit();
                      }}
                    >
                      Enviar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </CardPopup>
      {showPreview && payload && (
        <CardPopup
          className={styles['card-popup']}
          onClose={() => setShowPreview(false)}
          refClose={nodeRefPreview}
          closeable={false}
        >
          <QuestionAnswer
            question={{
              id: '',
              text: payload.question,
              options: [
                payload.aAnswer,
                payload.bAnswer,
                payload.cAnswer,
                payload.dAnswer,
              ],
              subject: categoriesQuery.data?.data?.find(
                (category) => category.id === payload.category,
              )?.title,
              selectedOption: payload.correctAnswer,
              correctOption: payload.correctAnswer ?? 0,
              reason: payload.reason,
            }}
            background="main"
            onClose={() => {
              nodeRefPreview.current?.click();
            }}
          />
        </CardPopup>
      )}
    </>
  );
}

export { EnglishQuestionEditor };
