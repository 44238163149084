/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { useToasterActions } from '../../../state/toasterStore';
import { QuestionDto } from '../questions';
import { ApiResponse } from '../response';
import { QuestionFeedbackReportDto } from './useFeedback';
import { PagedResponse } from './usePageRequest';

export interface AptitudeQuestionDto extends QuestionDto {
  category: number;
  categoryName: string;
  question: string;
  questionPayload: string;
  answers: string[];
  reason: string;
  bookmarked?: boolean;
  status: number;
}

export interface DetailedAptitudeQuestionDto extends AptitudeQuestionDto {
  author: string;
  creationDate: Date;
  trial: boolean;
  aptitudeTestId: string;
  aptitudeTestTitle: string;
}

export interface AptitudeQuestionPostDto {
  category: number;
  question: string;
  questionPayload: string;
  aAnswer: string;
  bAnswer: string;
  cAnswer: string;
  dAnswer: string;
  correctAnswer: number;
  reason: string;
  trial: boolean;
  aptitudeTestId?: string;
}

export function useAptitudeQuestion() {
  const { instance } = useAxios();
  const { sendMessage } = useToasterActions();

  async function getAptitudeQuestion(
    id: string,
  ): Promise<ApiResponse<AptitudeQuestionDto>> {
    try {
      const response = await instance.get<AptitudeQuestionDto>(
        `/aptitude/questions/${id}`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      if (error.response.status === 404) {
        sendMessage('¡Ups!', 'Parece que la pregunta no existe', 'danger');

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function getDetailedAptitudeQuestion(
    id: string,
  ): Promise<ApiResponse<DetailedAptitudeQuestionDto>> {
    try {
      const response = await instance.get<DetailedAptitudeQuestionDto>(
        `/aptitude/questions/${id}/detail`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getAptitudeQuestions(
    status: number[],
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<DetailedAptitudeQuestionDto>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
        status,
      },
    };

    try {
      const response = await instance.get<
        PagedResponse<DetailedAptitudeQuestionDto>
      >(`/aptitude/questions`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getRandomAptitudeQuestions(
    categories?: number[],
  ): Promise<ApiResponse<AptitudeQuestionDto>> {
    const config = {
      params: {
        categories,
      },
    };

    try {
      const response = await instance.get<AptitudeQuestionDto>(
        `/aptitude/questions/random`,
        config,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function addAptitudeQuestion(
    question: AptitudeQuestionPostDto,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(`/aptitude/questions`, question);

      sendMessage('¡Hecho!', 'Pregunta creada correctamente', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage(
          'Fallo de validación',
          'Parece que la pregunta ya existe o hay algún campo incorrecto',
          'danger',
        );

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function updateAptitudeQuestion(
    questionId: string,
    question: AptitudeQuestionPostDto,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(
        `/aptitude/questions/${questionId}`,
        question,
      );

      sendMessage('¡Hecho!', 'Pregunta actualizada correctamente', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage(
          'Fallo de validación',
          'Parece que la pregunta ya existe o hay algún campo incorrecto',
          'danger',
        );

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function deleteAptitudeQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(
        `/aptitude/questions/${questionId}`,
      );

      sendMessage(
        '¡Hecho!',
        'Pregunta marcada como obsoleta correctamente',
        'success',
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage(
          '¡Ups!',
          'Parece que la pregunta no existe o no tienes permisos para eliminarla',
          'danger',
        );

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function bookmarkAptitudeQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.put(
        `/aptitude/questions/${questionId}/bookmark`,
        {},
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function unbookmarkAptitudeQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(
        `/aptitude/questions/${questionId}/bookmark`,
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function reportAptitudeQuestionError(
    questionId: string,
    description: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(
        `/aptitude/questions/${questionId}/errors`,
        { description },
      );

      sendMessage('¡Error reportado!', 'Gracias compañerx 💙', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getAptitudeQuestionReportedErrors(
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<QuestionFeedbackReportDto>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    };

    try {
      const response = await instance.get<
        PagedResponse<QuestionFeedbackReportDto>
      >(`/aptitude/questions/errors`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function deleteAptitudeQuestionReportedError(
    questionId: string,
    feedbackId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(
        `/aptitude/questions/${questionId}/errors/${feedbackId}`,
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function verifyAptitudeQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.put(
        `/aptitude/questions/${questionId}/verify`,
        {},
      );

      sendMessage('¡Hecho!', 'Pregunta verificada correctamente', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage('¡Ups!', 'Parece que la pregunta no existe', 'danger');

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function rejectAptitudeQuestion(
    questionId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(
        `/aptitude/questions/${questionId}/verify`,
      );

      sendMessage('¡Hecho!', 'Pregunta rechazada correctamente', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage('¡Ups!', 'Parece que la pregunta no existe', 'danger');

        return {
          success: error.response.status === 400,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  return {
    getAptitudeQuestion,
    getDetailedAptitudeQuestion,
    getAptitudeQuestions,
    getRandomAptitudeQuestions,
    addAptitudeQuestion,
    updateAptitudeQuestion,
    deleteAptitudeQuestion,
    bookmarkAptitudeQuestion,
    unbookmarkAptitudeQuestion,
    reportAptitudeQuestionError,
    getAptitudeQuestionReportedErrors,
    deleteAptitudeQuestionReportedError,
    verifyAptitudeQuestion,
    rejectAptitudeQuestion,
  };
}
