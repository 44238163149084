import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';

import { Splash } from '../components/core/Splash';
import { useToasterActions } from '../state/toasterStore';
import { useAuth } from './AuthContext';
import { useAxios } from './AxiosContext';
import { useFirebase } from './FirebaseContext';

export interface GetUserResponse {
  id: string;
  identityProviderId: string;
  email: string;
  username?: string;
}

export interface NotificationsProfile {
  email: boolean;
  push: boolean;
}

export interface ConsentProfile {
  ad: boolean;
  analytics: boolean;
  functionality: boolean;
  personalization: boolean;
  security: boolean;
}

export interface ChallengesInfoProfile {
  points: number;
}

export interface UserAgreementProfile {
  accepted: boolean;
  version: number;
}

export interface SchoolProfile {
  id: string;
  name: string;
}

export interface SubscriptionProfile {
  isActive: boolean;
  isTrial: boolean;
}

export interface UserProfile {
  id?: string;
  identityProviderId?: string;
  email?: string;
  active?: boolean;
  banned?: boolean;
  username?: string;
  role?: string;
  name?: string;
  avatarUrl?: string;
  bio?: string;
  notifications?: NotificationsProfile;
  consent?: ConsentProfile;
  challengesInfo?: ChallengesInfoProfile;
  userAgreement?: UserAgreementProfile;
  school?: SchoolProfile;
  subscription?: SubscriptionProfile;
  signInMethod?: string;
}

interface ProfileProps {
  profile: UserProfile;
  setProfile: (value: UserProfile) => void;
}

const COLLECTION_NAME = 'users_v2';

export const ProfileContext = createContext<ProfileProps | null>(null);

export const ProfileContextProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const [profile, setProfile] = useState<UserProfile>();

  const { sendMessage } = useToasterActions();
  const { firebaseApp, analytics } = useFirebase();
  const { auth, user } = useAuth();
  const { instance } = useAxios();

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['getCurrentUser'],
    queryFn: () => instance.get<GetUserResponse>('v2/users/me'),
    enabled: user !== null,
  });

  useEffect(() => {
    if (response && response.status === 200 && response.data !== undefined) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        id: response.data.id,
        identityProviderId: response.data.identityProviderId,
        email: response.data.email,
        username: response.data.username,
      }));
    }
  }, [response]);

  useEffect(() => {
    if (user === null) {
      setProfile(undefined);
      return;
    }

    refetch();

    const firestore = getFirestore(firebaseApp);
    let signInMethod: string | undefined = undefined;

    if (
      user.providerData.some((provider) => provider.providerId === 'google.com')
    ) {
      signInMethod = 'google.com';
    } else {
      signInMethod = 'email';
    }

    const unsubscribe = onSnapshot(
      doc(firestore, COLLECTION_NAME, user.uid),
      (doc) => {
        if (!doc.exists()) {
          return;
        }

        setProfile((prevProfile) => ({
          ...doc.data(),
          signInMethod,
          id: prevProfile?.id,
        }));
      },
    );

    return () => unsubscribe();
  }, [firebaseApp, refetch, user]);

  useEffect(() => {
    if (profile === undefined) {
      return;
    }

    if (
      profile.username === undefined ||
      profile.username === null ||
      profile.username === ''
    ) {
      sendMessage(
        'Tu usuario no está registrado',
        'Antes de iniciar sesión, debes registrarte en la plataforma y completar tu perfil, haz click en "Regístrate" para crear una cuenta.',
        'danger',
      );
      auth.signOut();

      return;
    }

    Sentry.setUser({
      id: user?.uid,
    });

    if (profile.consent !== undefined && profile.consent.analytics === true) {
      firebaseApp.automaticDataCollectionEnabled = true;
      setAnalyticsCollectionEnabled(analytics, true);
      return;
    }

    firebaseApp.automaticDataCollectionEnabled = false;
    setAnalyticsCollectionEnabled(analytics, false);
  }, [analytics, auth, firebaseApp, profile, sendMessage, user?.uid]);

  if (profile === undefined || isLoading) {
    return <Splash />;
  }

  return (
    <ProfileContext.Provider
      value={{
        profile,
        setProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);

  if (!context) {
    throw new Error('useProfile must be used within a ProfileContextProvider');
  }

  return context;
};
