/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { useToasterActions } from '../../../state/toasterStore';
import { QuestionDto, TestDto, TestResult, TestResultDto } from '../questions';
import { ApiResponse } from '../response';
import { AptitudeQuestionDto } from './useAptitudeQuestion';
import { PagedResponse } from './usePageRequest';

export interface AptitudeTestRequest {
  size: number;
  categories: number[];
}

export interface AptitudeTestMetadataDto {
  id: string;
  title: string;
  completionTime: number;
  averageScore: number;
}

export interface CreateAptitudeTestRequest {
  title: string;
  completionTime: number;
}

export function useAptitudeTest() {
  const { instance } = useAxios();
  const { sendMessage } = useToasterActions();

  async function getRandomAptitudeTest(
    request: AptitudeTestRequest,
  ): Promise<ApiResponse<TestDto<AptitudeQuestionDto>>> {
    try {
      const response = await instance.post<TestDto<AptitudeQuestionDto>>(
        `/aptitude/tests/random`,
        request,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getBookmarkedAptitudeTest(): Promise<
    ApiResponse<TestDto<AptitudeQuestionDto>>
  > {
    try {
      const response = await instance.get<TestDto<AptitudeQuestionDto>>(
        `/aptitude/tests/bookmarked`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function markAptitudeTest(
    test: TestDto<QuestionDto>,
  ): Promise<ApiResponse<TestResultDto<AptitudeQuestionDto>>> {
    try {
      const response = await instance.post<TestResultDto<AptitudeQuestionDto>>(
        `/aptitude/tests/${test.id}/mark`,
        test,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getAptitudeTestResults(
    pageNumber = 1,
    pageSize = 10,
  ): Promise<ApiResponse<PagedResponse<TestResult>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    };

    try {
      const response = await instance.get<PagedResponse<TestResult>>(
        `/aptitude/tests/results`,
        config,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getAptitudeTestResult(
    id: string,
  ): Promise<ApiResponse<TestResultDto<AptitudeQuestionDto>>> {
    try {
      const response = await instance.get<TestResultDto<AptitudeQuestionDto>>(
        `/aptitude/tests/results/${id}`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      if (error.response.status === 404) {
        sendMessage(
          'Respuestas no disponibles',
          'El contenido de los simulacros más antiguos no está disponible 😔',
          'warning',
        );

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function getAptitudeTestsList(
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<AptitudeTestMetadataDto>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    };

    try {
      const response = await instance.get<
        PagedResponse<AptitudeTestMetadataDto>
      >(`/aptitude/tests/list`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getAptitudeTestsListWithQuestions(
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<AptitudeTestMetadataDto>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    };

    try {
      const response = await instance.get<
        PagedResponse<AptitudeTestMetadataDto>
      >(`/aptitude/tests`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getCompetitiveAptitudeTest(
    id: string,
  ): Promise<ApiResponse<TestDto<AptitudeQuestionDto>>> {
    try {
      const response = await instance.get<TestDto<AptitudeQuestionDto>>(
        `/aptitude/tests/${id}`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function createAptitudeTest(
    test: CreateAptitudeTestRequest,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(`/aptitude/tests`, test);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  return {
    getRandomAptitudeTest,
    getBookmarkedAptitudeTest,
    markAptitudeTest,
    getAptitudeTestResults,
    getAptitudeTestResult,
    getAptitudeTestsList,
    getAptitudeTestsListWithQuestions,
    getCompetitiveAptitudeTest,
    createAptitudeTest,
  };
}
