/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { QuestionDto } from '../questions';
import { ApiResponse } from '../response';

export interface Player {
  id: string;
  username: string;
  avatar: string;
  bot: boolean;
  points: number;
  score: number;
  active: boolean;
}

export interface Room {
  id: string;
  creationDate: Date;
  questions: number;
  questionTime: number;
  closed: boolean;
  players: Player[];
}

export interface QuestionResult {
  topPlayers: Player[];
}

export interface ChallengeResult {
  topPlayers: Player[];
}

export interface OfflineChallengeQuestionSubmitDto extends QuestionDto {
  timeInSeconds: number;
}

export function useChallenge() {
  const { instance } = useAxios();

  async function createOfflineChallenge(): Promise<ApiResponse<Room>> {
    try {
      const response = await instance.get<Room>(`/challenge/offline/new`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function markOfflineChallengeQuestion(
    question: OfflineChallengeQuestionSubmitDto,
  ): Promise<ApiResponse<QuestionResult>> {
    const payload = {
      id: question.id,
      correctAnswer: question.correctAnswer,
      selectedAnswer: question.selectedAnswer,
      timeInSeconds: question.timeInSeconds,
    };

    try {
      const response = await instance.post(
        `/challenge/offline/${question.id}/mark`,
        payload,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function uploadChallengeResult(
    result: ChallengeResult,
  ): Promise<ApiResponse<QuestionResult>> {
    try {
      const response = await instance.post(`/challenge/offline/result`, result);

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  return {
    createOfflineChallenge,
    markOfflineChallengeQuestion,
    uploadChallengeResult,
  };
}
