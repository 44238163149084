/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { useToasterActions } from '../../../state/toasterStore';
import { ApiResponse } from '../response';
import { SchemaFeedbackReportDto } from './useFeedback';
import { PagedResponse } from './usePageRequest';

export interface TheorySchemaDto {
  id: string;
  lesson: number;
  description: string;
  schemaPayload: string;
  trial: boolean;
  creationDate: Date;
  lastUpdate: Date;
}

export interface TheorySchemaSummaryDto {
  lesson: number;
  title: string;
  schemas: TheorySchemaDto[];
}

export interface TheorySchemaPostDto {
  lesson: number;
  description: string;
  schemaPayload: string;
  trial: boolean;
}

export function useTheorySchema() {
  const { instance } = useAxios();
  const { sendMessage } = useToasterActions();

  async function getSchema(id: string): Promise<ApiResponse<TheorySchemaDto>> {
    try {
      const response = await instance.get(`/theory/schemas/${id}`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getSchemaSummary(): Promise<
    ApiResponse<TheorySchemaSummaryDto[]>
  > {
    try {
      const response = await instance.get<TheorySchemaSummaryDto[]>(
        `/theory/schemas/summary`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function addTheorySchema(
    schema: TheorySchemaPostDto,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(`/theory/schemas`, schema);

      sendMessage('¡Hecho!', 'Esquema creado correctamente', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage(
          '¡Ups!',
          'Ha ocurrido un error inesperado, intentalo más tarde',
          'danger',
        );

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function updateTheorySchema(
    schemaId: string,
    schema: TheorySchemaPostDto,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(
        `/theory/schemas/${schemaId}`,
        schema,
      );

      sendMessage('¡Hecho!', 'Esquema actualizado correctamente', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage(
          'Fallo de validación',
          'Parece que el esquema ya existe o hay algún campo incorrecto',
          'danger',
        );

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function deleteTheorySchema(
    schemaId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(`/theory/schemas/${schemaId}`);

      sendMessage('¡Hecho!', 'Esquema eliminado correctamente', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      if (error.response.status === 400) {
        sendMessage(
          '¡Ups!',
          'Parece que el esquema no existe o no tienes permisos para eliminarla',
          'danger',
        );

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function reportTheorySchemaError(
    schemaId: string,
    description: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(
        `/theory/schemas/${schemaId}/errors`,
        {
          description,
        },
      );

      sendMessage('¡Error reportado!', 'Gracias compañerx 💙', 'success');

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getTheorySchemaReportedErrors(
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<SchemaFeedbackReportDto>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    };

    try {
      const response = await instance.get<
        PagedResponse<SchemaFeedbackReportDto>
      >(`/theory/schemas/errors`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function deleteTheorySchemaReportedError(
    schemaId: string,
    feedbackId: string,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(
        `/theory/schemas/${schemaId}/errors/${feedbackId}`,
      );

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  return {
    getSchema,
    getSchemaSummary,
    addTheorySchema,
    updateTheorySchema,
    deleteTheorySchema,
    reportTheorySchemaError,
    getTheorySchemaReportedErrors,
    deleteTheorySchemaReportedError,
  };
}
