import { useEffect, useState } from 'react';

import { Accordion, AccordionSkeleton, Card } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { IoLinkOutline } from 'react-icons/io5';

import { Page } from '../../../components/shared/page/Page';
import {
  SubjectDto,
  useSubjects,
} from '../../../services/api/hooks/useSubjects';
import { getLessonColor } from '../../../utils/colors';

import styles from './TheoryRegulationsPage.module.scss';

function TheoryRegulationsPage() {
  const [subject, setSubject] = useState<SubjectDto>();

  const { getTheorySubject } = useSubjects();

  const { data: response, isLoading } = useQuery({
    queryKey: ['getTheorySubject'],
    queryFn: getTheorySubject,
    gcTime: 5 * (60 * 1000),
  });

  useEffect(() => {
    if (response && response.data) {
      setSubject(response.data);
    }
  }, [response]);

  return (
    <Page title="Normativa Escala Básica">
      <Card>
        {isLoading || !subject ? (
          <AccordionSkeleton items={25} />
        ) : (
          <>
            {subject.units.map((unit) => (
              <>
                <div className={styles['title']}>{unit.name}</div>
                <Accordion
                  key={unit.id}
                  items={unit.lessons
                    .filter((lesson) => lesson.resources.length > 0)
                    .map((item) => ({
                      label: {
                        value: ('0' + item.index).slice(-2),
                        backgroundColor: getLessonColor(item.index),
                      },
                      text: item.name,
                      info: item.outline,
                      items: item.resources.map((resource) => ({
                        text: resource.name,
                        icon: (
                          <IoLinkOutline
                            style={{
                              cursor: 'pointer',
                              fontSize: '20px',
                              minWidth: '20px',
                              margin: '0px',
                            }}
                            onClick={() => window.open(resource.url, '_blank')}
                          />
                        ),
                      })),
                    }))}
                />
              </>
            ))}
          </>
        )}
      </Card>
    </Page>
  );
}

export { TheoryRegulationsPage };
