import { useEffect, useState } from 'react';

import {
  BarChart,
  BarChartSkeleton,
  Card,
  LineChart,
  SelectDropdown,
} from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import {
  IoClipboardOutline,
  IoFileTrayFullOutline,
  IoRibbonOutline,
  IoStatsChartOutline,
  IoTrendingUpSharp,
} from 'react-icons/io5';

import { Page } from '../../components/shared/page/Page';
import { ValueCard } from '../../components/shared/value-card/ValueCard';
import { StatsDto, useStats } from '../../services/api/hooks/useStats';

import styles from './StatisticsPage.module.scss';

function StatisticsPage() {
  const [selectedValue, setSelectedValue] = useState('theory');
  const [stats, setStats] = useState<StatsDto>();

  const { getTheoryStats, getAptitudeStats, getEnglishStats } = useStats();

  const { data: response, isLoading } = useQuery({
    queryKey: ['getStats', selectedValue],
    queryFn: () =>
      selectedValue === 'theory'
        ? getTheoryStats()
        : selectedValue === 'aptitude'
          ? getAptitudeStats()
          : getEnglishStats(),
    gcTime: 5 * (60 * 1000), // 5 mins
  });

  useEffect(() => {
    if (response?.data !== undefined) {
      setStats(response.data);
    }
  }, [response]);

  return (
    <Page title="Progreso">
      <SelectDropdown
        className={styles['dropdown']}
        onSelect={(value) => setSelectedValue(value as string)}
        options={[
          {
            value: 'theory',
            label: 'Teoría',
            selected: true,
          },
          {
            value: 'aptitude',
            label: 'Psicotécnicos',
          },
          {
            value: 'english',
            label: 'Inglés',
          },
        ]}
      />
      <div className={styles['row']}>
        <ValueCard
          className={styles['value-card']}
          icon={<IoFileTrayFullOutline />}
          title="Total simulacros"
          value={
            stats?.totalCount && stats?.totalCount > 500
              ? '500+'
              : stats?.totalCount.toString() ?? ''
          }
          loading={isLoading}
        />
        <ValueCard
          className={styles['value-card']}
          icon={<IoTrendingUpSharp />}
          title="Nota media"
          value={stats?.averageScore.toString() ?? ''}
          loading={isLoading}
        />
        <ValueCard
          className={styles['value-card']}
          icon={<IoRibbonOutline />}
          title="Nota más alta"
          value={stats?.maxScore.toString() ?? ''}
          loading={isLoading}
        />
      </div>
      <div className={styles['row']}>
        <Card
          className={styles['chart-card']}
          icon={<IoStatsChartOutline />}
          title="Resultados en simulacros"
          caption="Últimos 20 simulacros"
        >
          {isLoading ? (
            <BarChartSkeleton labels={2} />
          ) : (
            <LineChart
              className={styles['chart']}
              labels={
                stats && stats.lastResults && stats.lastResults.length > 0
                  ? stats.lastResults.map((_, index) => (index + 1).toString())
                  : []
              }
              dataset={[
                {
                  label: 'Calificación',
                  values:
                    stats && stats.lastResults && stats.lastResults.length > 0
                      ? stats.lastResults.map((result) => result.mark)
                      : [],
                },
              ]}
            />
          )}
        </Card>
      </div>
      <div className={styles['row']}>
        <Card
          className={styles['chart-card']}
          icon={<IoClipboardOutline />}
          title="Respuestas en simulacros"
          caption="Últimos 20 simulacros"
        >
          {isLoading ? (
            <BarChartSkeleton labels={2} />
          ) : (
            <BarChart
              className={styles['chart']}
              labels={
                stats && stats.lastResults && stats.lastResults.length > 0
                  ? stats.lastResults.map((_, index) => (index + 1).toString())
                  : []
              }
              dataset={[
                {
                  label: 'Correctas',
                  values:
                    stats && stats.lastResults && stats.lastResults.length > 0
                      ? stats.lastResults.map((result) => result.correctAnswers)
                      : [],
                  color: '#50b06d',
                },
                {
                  label: 'Erróneas',
                  values:
                    stats && stats.lastResults && stats.lastResults.length > 0
                      ? stats.lastResults.map((result) => result.wrongAnswers)
                      : [],
                  color: '#e86767',
                },
                {
                  label: 'Sin contestar',
                  values:
                    stats && stats.lastResults && stats.lastResults.length > 0
                      ? stats.lastResults.map((result) => result.emptyAnswers)
                      : [],
                  color: '#a8d8e9',
                },
              ]}
              stacked
            />
          )}
        </Card>
      </div>
    </Page>
  );
}

export { StatisticsPage };
