/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { QuestionDto, TestDto, TestResult, TestResultDto } from '../questions';
import { ApiResponse } from '../response';
import { EnglishQuestionDto } from './useEnglishQuestion';
import { PagedResponse } from './usePageRequest';

export interface EnglishTestRequest {
  size: number;
  categories: number[];
}

export function useEnglishTest() {
  const { instance } = useAxios();

  async function getRandomEnglishTest(
    request: EnglishTestRequest,
  ): Promise<ApiResponse<TestDto<EnglishQuestionDto>>> {
    try {
      const response = await instance.post<TestDto<EnglishQuestionDto>>(
        `/english/tests/random`,
        request,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getBookmarkedEnglishTest(): Promise<
    ApiResponse<TestDto<EnglishQuestionDto>>
  > {
    try {
      const response = await instance.get<TestDto<EnglishQuestionDto>>(
        `/english/tests/bookmarked`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function markEnglishTest(
    test: TestDto<QuestionDto>,
  ): Promise<ApiResponse<TestResultDto<EnglishQuestionDto>>> {
    try {
      const response = await instance.post<TestResultDto<EnglishQuestionDto>>(
        `/english/tests/${test.id}/mark`,
        test,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getEnglishTestResults(
    pageNumber = 1,
    pageSize = 10,
  ): Promise<ApiResponse<PagedResponse<TestResult>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    };

    try {
      const response = await instance.get<PagedResponse<TestResult>>(
        `/english/tests/results`,
        config,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getEnglishTestResult(
    id: string,
  ): Promise<ApiResponse<TestResultDto<EnglishQuestionDto>>> {
    try {
      const response = await instance.get<TestResultDto<EnglishQuestionDto>>(
        `/english/tests/results/${id}`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  return {
    getRandomEnglishTest,
    getBookmarkedEnglishTest,
    markEnglishTest,
    getEnglishTestResults,
    getEnglishTestResult,
  };
}
