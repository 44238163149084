/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import { CardPopup, SignIn } from '@sealfye/ui-components';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';

import { useConfiguration } from '../../../context/ConfigurationContext';
import { useFirebase } from '../../../context/FirebaseContext';
import { useToasterActions } from '../../../state/toasterStore';
import { ResetPasswordPopup } from '../popups/ResetPasswordPopup';

import styles from './Login.module.scss';

function Login() {
  const { firebaseApp } = useFirebase();
  const { configuration } = useConfiguration();
  const auth = getAuth(firebaseApp);
  const { sendMessage } = useToasterActions();
  const provider = new GoogleAuthProvider();
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);

  return (
    <div className={styles['main']}>
      <CardPopup className={styles['pop-up']}>
        <div className={styles['wrapper']}>
          <SignIn
            onLoginWithGoogle={async () => {
              try {
                await signInWithPopup(auth, provider);
              } catch (error: any) {
                sendMessage(
                  '¡Vaya!',
                  'Parece que algo ha ido mal, intentalo más tarde',
                  'danger',
                );
              }
            }}
            onSignInWithEmailAndPassword={async (email, password) => {
              try {
                await signInWithEmailAndPassword(auth, email, password);
              } catch (error: any) {
                if (error.code === 'auth/operation-not-allowed') {
                  sendMessage(
                    'Acceso temporalmente deshabilitado',
                    'El acceso de usuarios está temporalmente deshabilitado. Estamos trabajando en los últimos ajustes antes de poder ayudarte a cumplir tu sueño 💙',
                    'neutral',
                  );
                } else if (error.code === 'auth/invalid-email') {
                  sendMessage(
                    '¡Ups!',
                    'El correo electrónico introducido no es válido.',
                    'danger',
                  );
                } else if (
                  error.code === 'auth/user-not-found' ||
                  error.code === 'auth/wrong-password'
                ) {
                  sendMessage(
                    '¡Ups!',
                    'El correo electrónico o la contraseña introducidos no son correctos.',
                    'danger',
                  );
                } else if (error.code === 'auth/too-many-requests') {
                  sendMessage(
                    '¡Ups!',
                    'Se ha alcanzado el límite de intentos de acceso. Por favor, inténtalo más tarde.',
                    'danger',
                  );
                } else {
                  sendMessage(
                    '¡Vaya!',
                    'Parece que algo ha ido mal, intentalo más tarde.',
                    'danger',
                  );
                }
              }
            }}
            onResetPassword={() => setShowResetPasswordPopup(true)}
            onRegister={() => {
              window.open(`${configuration.app?.pageUrl}/mi-cuenta`, '_blank');
            }}
          />
        </div>
      </CardPopup>
      {showResetPasswordPopup && (
        <ResetPasswordPopup
          onClose={() => {
            setShowResetPasswordPopup(!showResetPasswordPopup);
          }}
        />
      )}
    </div>
  );
}

export { Login };
