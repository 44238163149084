/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { ApiResponse } from '../response';
import {
  AptitudeQuestionDto,
  DetailedAptitudeQuestionDto,
} from './useAptitudeQuestion';
import {
  DetailedEnglishQuestionDto,
  EnglishQuestionDto,
} from './useEnglishQuestion';
import {
  DetailedOrthographyQuestionDto,
  OrthographyQuestionDto,
} from './useOrthographyQuestion';
import { PagedResponse } from './usePageRequest';
import {
  DetailedTheoryQuestionDto,
  TheoryQuestionDto,
} from './useTheoryQuestion';

export interface UserDto {
  username: string;
  school: string;
  points: number;
}

export function useUsers() {
  const { instance } = useAxios();

  async function setupUser(): Promise<ApiResponse<any>> {
    try {
      const response = await instance.get(`/users/setup`);

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function deleteUser(): Promise<ApiResponse<any>> {
    try {
      const response = await instance.delete(`/users/self`);

      return {
        success: response.status === 200,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getCreatedTheoryQuestions(
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<DetailedTheoryQuestionDto>>> {
    try {
      const config = {
        params: {
          pageNumber: pageNumber,
          pageSize: pageSize,
          sort: 'desc',
        },
      };

      const response = await instance.get<
        PagedResponse<DetailedTheoryQuestionDto>
      >(`/users/self/theory/questions`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getTheoryQuestionsBookmarked(
    pageNumber = 1,
    pageSize = 15,
  ): Promise<ApiResponse<PagedResponse<TheoryQuestionDto>>> {
    try {
      const config = {
        params: {
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };

      const response = await instance.get<PagedResponse<TheoryQuestionDto>>(
        `/users/self/theory/questions/bookmarked`,
        config,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getCreatedOrthographyQuestions(
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<DetailedOrthographyQuestionDto>>> {
    try {
      const config = {
        params: {
          pageNumber: pageNumber,
          pageSize: pageSize,
          sort: 'desc',
        },
      };

      const response = await instance.get<
        PagedResponse<DetailedOrthographyQuestionDto>
      >(`/users/self/orthography/questions`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getOrthographyQuestionsBookmarked(
    pageNumber = 1,
    pageSize = 15,
  ): Promise<ApiResponse<PagedResponse<OrthographyQuestionDto>>> {
    try {
      const config = {
        params: {
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };

      const response = await instance.get<
        PagedResponse<OrthographyQuestionDto>
      >(`/users/self/orthography/questions/bookmarked`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getTopUsersByPoints(): Promise<ApiResponse<UserDto[]>> {
    try {
      const response = await instance.get<UserDto[]>(`/users/points/top`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getCreatedAptitudeQuestions(
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<DetailedAptitudeQuestionDto>>> {
    try {
      const config = {
        params: {
          pageNumber: pageNumber,
          pageSize: pageSize,
          sort: 'desc',
        },
      };

      const response = await instance.get<
        PagedResponse<DetailedAptitudeQuestionDto>
      >(`/users/self/aptitude/questions`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getAptitudeQuestionsBookmarked(
    pageNumber = 1,
    pageSize = 15,
  ): Promise<ApiResponse<PagedResponse<AptitudeQuestionDto>>> {
    try {
      const config = {
        params: {
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };

      const response = await instance.get<PagedResponse<AptitudeQuestionDto>>(
        `/users/self/aptitude/questions/bookmarked`,
        config,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getCreatedEnglishQuestions(
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<DetailedEnglishQuestionDto>>> {
    try {
      const config = {
        params: {
          pageNumber: pageNumber,
          pageSize: pageSize,
          sort: 'desc',
        },
      };

      const response = await instance.get<
        PagedResponse<DetailedEnglishQuestionDto>
      >(`/users/self/english/questions`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  async function getEnglishQuestionsBookmarked(
    pageNumber = 1,
    pageSize = 15,
  ): Promise<ApiResponse<PagedResponse<EnglishQuestionDto>>> {
    try {
      const config = {
        params: {
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };

      const response = await instance.get<PagedResponse<EnglishQuestionDto>>(
        `/users/self/english/questions/bookmarked`,
        config,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      return {
        success: false,
      };
    }
  }

  return {
    setupUser,
    deleteUser,
    getCreatedTheoryQuestions,
    getCreatedOrthographyQuestions,
    getOrthographyQuestionsBookmarked,
    getTopUsersByPoints,
    getCreatedAptitudeQuestions,
    getAptitudeQuestionsBookmarked,
    getCreatedEnglishQuestions,
    getEnglishQuestionsBookmarked,
    getTheoryQuestionsBookmarked,
  };
}
