import { useState } from 'react';

import { Button, ButtonGroup } from '@sealfye/ui-components';
import { useNavigate } from 'react-router-dom';

import TestImg from '../../../assets/images/exam.svg';
import TheoryImg from '../../../assets/images/theory-books.svg';
import { Lessons } from '../../../components/features/theory/Lessons';
import { Page } from '../../../components/shared/page/Page';
import { SectionCard } from '../../../components/shared/section-card/SectionCard';

function TheoryQuestionnairesPage() {
  const [questions, setQuestions] = useState(15);
  const [questionnaireType, setQuestionnaireType] = useState<
    'simple-questionnaire' | 'questionnaire'
  >('simple-questionnaire');
  const [showLessons, setShowLessons] = useState(false);

  const navigate = useNavigate();

  return (
    <Page title="Teoría">
      <SectionCard
        title="Preguntas de teoría sueltas"
        image={TheoryImg}
        button={
          <Button
            variant="outline-primary"
            onClick={() => {
              setQuestionnaireType('simple-questionnaire');
              setShowLessons(true);
            }}
          >
            Preguntas sueltas
          </Button>
        }
      >
        <p>
          Responde preguntas sueltas con explicación al responder. Más de 10.000
          preguntas disponibles sin límite de tiempo.
        </p>
      </SectionCard>
      <SectionCard
        title="Simulacro"
        image={TestImg}
        button={
          <Button
            variant="outline-primary"
            onClick={() => {
              setQuestionnaireType('questionnaire');
              setShowLessons(true);
            }}
          >
            Simulacro
          </Button>
        }
      >
        <p>
          El temario para el examen oficial de teoría de Policía Nacional Escala
          Básica consta de 44 temas que se dividen en 3 partes: ciencias
          jurídicas, ciencias sociales y ciencias técnico científicas. La
          duración y el número de preguntas oficial es de 100 preguntas a
          responder en un máximo de 50 minutos.
        </p>
        <p>
          Cada pregunta tiene tres alternativas de respuesta de las cuales solo
          una es correcta.
        </p>
        <p>
          Si seleccionas menos preguntas el tiempo se ajustará
          proporcionalmente.
        </p>
        <ButtonGroup
          onSelect={(value) => setQuestions(+value)}
          options={[
            {
              value: 15,
              label: '15',
              selected: true,
            },
            {
              value: 25,
              label: '25',
            },
            {
              value: 50,
              label: '50',
            },
            {
              value: 100,
              label: '100',
            },
          ]}
        />
      </SectionCard>
      {showLessons && (
        <Lessons
          onSelect={(ids) => {
            if (questionnaireType === 'simple-questionnaire') {
              navigate({
                pathname: '/teoria/test',
                search: `?${ids.length > 0 ? `temas=${ids.join(',')}` : ''}`,
              });
            } else {
              navigate({
                pathname: '/teoria/simulacro',
                search: `?temas=${ids.join(',')}` + `&preguntas=${questions}`,
              });
            }
          }}
          onClose={() => {
            setShowLessons(false);
          }}
        />
      )}
    </Page>
  );
}

export { TheoryQuestionnairesPage };
