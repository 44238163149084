// sort-imports-ignore
// eslint-disable-next-line import/no-unresolved
import '@sealfye/ui-components/dist/index.css';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { captureException, withScope } from '@sentry/react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { Environment } from './components/core/layout/Environment';
import { Login } from './components/core/layout/Login';
import { Toaster } from './components/core/layout/Toaster';
import { PwaReloadPopup } from './components/core/popups/PwaReloadPopup';
import { AuthContextProvider } from './context/AuthContext';
import { AxiosContextProvider } from './context/AxiosContext';
import { ConfigurationContextProvider } from './context/ConfigurationContext';
import { FirebaseContextProvider } from './context/FirebaseContext';
import { ProfileContextProvider } from './context/ProfileContext';
import { PwaContextProvider } from './context/PwaContext';
import { ThemeContextProvider } from './context/ThemeContext';
import { Auth } from './middlewares/Auth';
import { Monitoring } from './middlewares/Monitoring';
import { WebVitals } from './middlewares/WebVitals';

import './styles/index.scss';

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            // Duplicate to prevent modification
            Array.from(mutation.options.mutationKey) as string[],
          );
        }
        captureException(error);
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (error, query) => {
      withScope((scope) => {
        scope.setContext('query', { queryHash: query.queryHash });
        scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, '0')]);
        captureException(error);
      });
    },
  }),
  defaultOptions: {
    queries: {
      staleTime: 0 * (60 * 1000), // 0 mins
      gcTime: 0 * (60 * 1000), // 0 mins
      refetchOnWindowFocus: false,
    },
    mutations: {
      gcTime: 0 * (60 * 1000), // 0 mins
    },
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <PwaContextProvider>
      <ThemeContextProvider>
        <QueryClientProvider client={queryClient}>
          <ConfigurationContextProvider>
            <Monitoring>
              <FirebaseContextProvider>
                <WebVitals>
                  <AuthContextProvider>
                    <BrowserRouter>
                      <Environment>
                        <Auth fallback={<Login />}>
                          <AxiosContextProvider>
                            <ProfileContextProvider>
                              <App />
                            </ProfileContextProvider>
                          </AxiosContextProvider>
                        </Auth>
                      </Environment>
                      <Toaster />
                    </BrowserRouter>
                  </AuthContextProvider>
                </WebVitals>
              </FirebaseContextProvider>
            </Monitoring>
          </ConfigurationContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeContextProvider>
      <PwaReloadPopup />
    </PwaContextProvider>
  </StrictMode>,
);
